<template>
  <div>
    <el-page-header
      @back="goBack"
      :content="`${category.name}`"
    ></el-page-header>

    <div class="header">
      <div></div>
      <div>
        <el-button
          type="primary"
          size="default"
          @click="addDialogVisiable = true"
          >{{ $t("CategoryDetailsPageAddNewSubcategoryBtn") }}</el-button
        >
      </div>
    </div>
    <div class="body">
      <el-table :data="subCategoiresList" border stripe>
        <el-table-column
          prop="image"
          :label="$t('CategoryDetailsPageImageColumnTitle')"
          width="200"
        >
          <template slot-scope="scope">
            <div>
              <div style="text-align: center">
                <img
                  width="100"
                  height="100"
                  style="object-fit: scale-down"
                  :src="scope.row.image"
                />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="id"
          :label="$t('CategoryDetailsPageIDColumnTitle')"
          width="200"
        >
        </el-table-column>

        <el-table-column
          prop="name"
          :label="$t('CategoryDetailsPageDetailsColumnTitle')"
          width="200"
        >
          <template slot-scope="scope">
            <div>
              <el-input
                v-if="scope.row.isEditable"
                v-model="scope.row.newName"
                placeholder=""
                size="normal"
                clearable
              ></el-input>
              <span v-else> {{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="displayOrder"
          :label="$t('CategoryDetailsPageDisplayOrderColumnTitle')"
        >
          <template slot-scope="scope">
            <div>
              <el-input
                v-if="scope.row.isEditable"
                v-model="scope.row.newDisplayOrder"
                placeholder=""
                size="normal"
                clearable
              ></el-input>
              <span v-else> {{ scope.row.displayOrder }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('CategoriesPageOperationColumnTitle')">
          <template slot-scope="scope">
            <el-button
              type="success"
              size="default"
              v-if="!scope.row.isEditable"
              @click="setCategoryEditable(scope.row, true)"
              >{{ $t("GlobalsEditBtn") }}
            </el-button>
            <template v-else>
              <el-button
                type="primary"
                size="default"
                @click="updateCategoriesDisplayOrder(scope.row)"
                >{{ $t("GlobalsSaveBtn") }}
              </el-button>
              <el-button
                type="danger"
                size="default"
                @click="setCategoryEditable(scope.row, false)"
                >{{ $t("GlobalsCancelBtn") }}
              </el-button>
            </template>
            <el-button
              type="danger"
              size="default"
              @click="deleteCategory(scope.row)"
              >{{ $t("GLobalsDelete") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="$t('CategoryDetailsPageAddNewSubCategorDialogTitle')"
      :visible.sync="addDialogVisiable"
      width="40%"
    >
      <el-form
        :model="addForm"
        ref="form"
        label-width="150px"
        :inline="false"
        size="normal"
      >
        <el-form-item :label="$t('CategoryDetailsPageNameDialogLabel')">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('CategoryDetailsPageDisplayOrderDialogLabel')">
          <el-input v-model="addForm.displayOrder"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="addDialogVisiable = false">{{
          $t("CategoryDetailsPageCancelDialogBtn")
        }}</el-button>
        <el-button type="primary" @click="createSubCategory">{{
          $t("CategoryDetailsPageConfirmDialogBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { categoriesService } from "@/services/categories.service";
export default {
  data() {
    return {
      subCategoiresList: [],
      addForm: {},
      category: {},
      addDialogVisiable: false,
    };
  },
  created() {
    this.getcategory();
  },
  computed: {
    categoryId() {
      return this.$route.params.id;
    },
  },
  methods: {
    getcategory() {
      this.$loading();
      categoriesService
        .getCategory(this.categoryId)
        .then((res) => {
          this.subCategoiresList = res.data.category.subcategories;
          this.category = res.data.category;
        })
        .finally(() => {
          this.$loading().close();
        });
    },

    createSubCategory() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.addForm.categoryId = this.categoryId;
          categoriesService.createSubCategory(this.addForm).then(() => {
            this.getcategory();
            this.addForm = {};
            this.addDialogVisiable = false;
          });
        } else {
          return false;
        }
      });
    },
    async updateCategoriesDisplayOrder(category) {
      try {
        this.$loading();
        await categoriesService.updateSubCategory(category.id, {
          displayOrder: category.newDisplayOrder,
          name: category.newName,
        });

        this.$set(category, "isEditable", false);
        this.$set(category, "displayOrder", category.newDisplayOrder);
        this.$set(category, "name", category.newName);
      } catch (error) {
        console.log(error);
      } finally {
        this.$loading().close();
      }
    },
    setCategoryEditable(category, editable) {
      // adding new properties and use set to fix  the vue reactivity issue
      this.$set(category, "isEditable", editable);
      this.$set(category, "newDisplayOrder", category.displayOrder);
      this.$set(category, "newName", category.name);
    },
    async deleteCategory(category) {
      this.$confirm(
        this.$t("SubCategoriesPageDeleteDialogMessage"),
        this.$t("SubCategoriesPageDeleteDialogTitle"),
        {
          confirmButtonText: this.$t("GlobalOkBtn"),
          cancelButtonText: this.$t("GlobalCancelBtn"),
          type: "warning",
        }
      ).then(async () => {
        this.$loading();
        await categoriesService.deleteSubCategory(category.id);
        this.getcategory();
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}
</style>
